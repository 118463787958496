import { FC, useState } from 'react'
import { Tooltip as BaseTooltip, TooltipProps } from '@mui/material'
import clsx from 'clsx'
import styles from './Tooltip.module.scss'

export type MyTooltipProps = TooltipProps & { activateOnClick?: boolean }

export const Tooltip: FC<MyTooltipProps> = (props) => {
  const { classes, activateOnClick = false, children, ...restProps } = props
  const [showTooltip, setShowTooltip] = useState(false)

  const child = activateOnClick ? (
    <span onClick={() => setShowTooltip((active) => !active)}>{children}</span>
  ) : (
    children
  )
  if (activateOnClick) {
    //managed
    return (
      <BaseTooltip
        key={'managed'}
        classes={{
          ...classes,
          tooltipArrow: clsx(styles.tooltip, classes?.tooltipArrow),
        }}
        {...restProps}
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        className="tooltip"
      >
        {child}
      </BaseTooltip>
    )
  }
  //auto
  return (
    <BaseTooltip
      key={'auto'}
      classes={{ ...classes, tooltipArrow: clsx(styles.tooltip, classes?.tooltipArrow) }}
      className="tooltip"
      {...restProps}
    >
      {child}
    </BaseTooltip>
  )
}
